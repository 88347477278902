/* :: 8.0 Hero Area CSS */

.welcome-area {
    position: relative;
    z-index: 1;

    .icon-scroll {
        width: 26px;
        height: 39px;
        position: absolute;
        z-index: 999999;
        left: 50%;
        bottom: 40px;
        margin-left: -13px;
        @include box-shadow (inset 0 0 0 2px $white);
        border-radius: 25px;
        opacity: 0.75;
        cursor: pointer;

        &::before {
            position: absolute;
            z-index: 999999;
            content: '';
            width: 6px;
            height: 6px;
            background: $white;
            left: 50%;
            @include transform(translateX(-50%));
            top: 8px;
            border-radius: 4px;
            @include animation(iconScroll linear 1200ms infinite);
        }
    }
}

@-webkit-keyframes iconScroll {

    0% {
        top: 8px;
        opacity: 1;
    }

    50% {
        opacity: 0;
        top: 23px;
    }

    100% {
        top: 8px;
        opacity: 0;
    }
}

@keyframes iconScroll {

    0% {
        top: 8px;
        opacity: 1;
    }

    50% {
        opacity: 0;
        top: 23px;
    }

    100% {
        top: 8px;
        opacity: 0;
    }
}

.welcome-slides {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .owl-prev,
    .owl-next {
        @include transition-duration(500ms);
        border-radius: 50%;
        position: absolute;
        width: 46px;
        height: 46px;
        top: 50%;
        left: 70px;
        margin-top: -33px;
        z-index: 10;
        text-align: center;
        line-height: 44px;
        border: 2px solid $secondary;
        color: $secondary;
        font-size: 34px;

        &:hover,
        &:focus {
            background-color: rgba(255, 255, 255, 0.5);
            color: $dark;
        }

        @media #{$breakpoint-md} {
            left: 30px;
        }

        @media #{$breakpoint-xs} {
            width: 40px;
            left: 15px;
            height: 40px;
            font-size: 18px;
            line-height: 38px;
        }
    }

    .owl-next {
        left: auto;
        right: 70px;

        @media #{$breakpoint-md} {
            right: 30px;
        }

        @media #{$breakpoint-xs} {
            right: 15px;
        }
    }
}

.single-welcome-slide {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background-color: $primary;


    .welcome-text {
        position: relative;
        z-index: 1;

        h2 {
            position: relative;
            z-index: 1;
            font-weight: 700;
            font-size: 90px;
            text-transform: uppercase;
            display: block;
            margin-bottom: 10px;
            color: $white;

            @media #{$breakpoint-md} {
                font-size: 60px;
            }

            @media #{$breakpoint-xs} {
                font-size: 30px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 42px;
            }

            &::after {
                position: absolute;
                content: "";
                height: 23px;
                width: 400px;
                top: 67px;
                right: 5px;
                z-index: -1;
                background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
                background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);

                @media #{$breakpoint-md} {
                    top: 40px;
                    height: 15px;
                }

                @media #{$breakpoint-xs} {
                    top: 45px;
                    height: 20px;
                    right: 0;
                    width: 240px;
                }

                @media #{$breakpoint-xs-landscape} {
                    top: 20px;
                }
            }
        }

        h6 {
            color: $white;
            margin-bottom: 50px;
            text-transform: uppercase;
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.10);
            padding: 10px 20px;
            font-size: 18px;

            @media #{$breakpoint-xs} {
                font-size: 14px;
            }
        }

        .hero-mail-contact {
            color: $white;
            font-style: italic;
            text-decoration: underline;

            &:hover,
            &:focus {
                color: $hover;
            }
        }
    }

    .welcome-text-two {
        position: relative;
        z-index: 1;

        h5 {
            font-size: 40px;
            color: $white;
            //text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 700;

            @media #{$breakpoint-xs} {
                font-size: 20px;
            }
        }

        h2 {
            font-size: 90px;
            color: $white;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 700;

            @media #{$breakpoint-md} {
                font-size: 62px;
            }

            @media #{$breakpoint-xs} {
                font-size: 30px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 42px;
            }
        }

        .event-meta {
            position: relative;
            z-index: 1;
            margin-bottom: 50px;
            font-size: 30px;

            a {
                color: $white;
                margin-right: 40px;

                @media #{$breakpoint-xs} {
                    margin-right: 15px;
                    font-size: 12px;
                }

                i {
                    margin-right: 5px;
                }
            }
        }
    }
}
