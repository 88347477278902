/* :: Footer Area CSS */

.footer-area {
    position: relative;
    z-index: 1;
    background-color: $text-3;

    .row {
        margin-left: -30px;
        margin-right: -30px;

        @media #{$breakpoint-xs} {
            margin-left: -15px;
            margin-right: -15px;
        }

        .col-12 {
            padding-left: 30px;
            padding-right: 30px;

            @media #{$breakpoint-xs} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.main-footer-area {
    position: relative;
    z-index: 1;

    .single-footer-widget {
        .widget-title {
            font-size: 20px;
            color: $white;
            margin-bottom: 20px;
        }

        p {
            color: $text-2;
            font-size: 14px;

            i {
                margin-right: 5px;
            }
        }

        .footer-logo {
            display: block;
            margin-bottom: 20px;
        }

        .social-info {
            a {
                height: 34px;
                width: 34px;
                border: 1px solid $border-5;
                border-radius: 50%;
                display: inline-block;
                text-align: center;
                line-height: 32px;
                color: $white;
                font-size: 15px;
                margin-right: 8px;
                @include transition-duration(500ms);

                &:hover,
                &:focus {
                    background-color: $text;
                    border-color: $text;
                }
            }
        }

        .footer-contact-info {
            position: relative;
            z-index: 1;

            p {
                margin-bottom: 13px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .footer-nav {
            position: relative;
            z-index: 1;

            li {
                a {
                    color: $text-2;
                    font-size: 14px;
                    margin-bottom: 15px;
                    display: inline-block;
                    @include transition-duration(500ms);

                    &:hover,
                    &:focus {
                        color: $white;
                    }
                }

                &:last-child {
                    a {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .footer-gallery {
            .row {
                margin-right: -5px;
                margin-left: -5px;

                .col-4 {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            a {
                position: relative;
                z-index: 1;
                display: inline-block;
                margin-bottom: 10px;
                border-radius: 5px;
                width: 100%;

                img {
                    border-radius: 5px;
                    width: 100%;
                }

                &::before {
                    background: #c63fa4;
                    background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
                    background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
                    content: "";
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    opacity: 0;
                    visibility: hidden;
                    @include transition-duration (300ms);
                    border-radius: 5px;
                }

                &:hover {
                    &::before {
                        visibility: visible;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

.copywrite-content {
    border-top: 1px solid $border-6;
    padding: 15px 0;

    @media #{$breakpoint-xs} {
        text-align: center;
    }

    .copywrite-text {
        p {
            color: $text-2;
            font-size: 14px;
            margin-bottom: 0;

            @media #{$breakpoint-md} {
                font-size: 12px;
            }

            @media #{$breakpoint-xs} {
                font-size: 12px;
            }

            a {
                color: $white;
                font-weight: 400;

                &:hover,
                &:focus {
                    color: $hover;
                }
            }
        }
    }

    .footer-menu {
        position: relative;
        z-index: 1;

        .nav {
            @include justify-content-end;

            @media #{$breakpoint-xs} {
                @include justify-content-center;
            }

            li {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: $text-2;
                    font-size: 14px;
                    font-weight: 400;

                    @media #{$breakpoint-md} {
                        font-size: 12px;
                    }

                    @media #{$breakpoint-xs} {
                        font-size: 12px;
                    }

                    i {
                        position: relative;
                        z-index: 1;
                        top: -2px;
                        font-size: 6px;
                        margin-right: 2px;
                        color: #505add;
                    }

                    &:hover,
                    &:focus {
                        color: $white;
                    }
                }
            }
        }
    }
}