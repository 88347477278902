/* :: 01.0 Reboot Area CSS */

* {
    margin: 0;
    padding: 0;
}

html {
	overflow: auto;
}

body {
    font-family: $font-poppins;
    font-weight: 400;
    font-size: 16px;
    position: relative;
    z-index: auto;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-poppins;
    color: $heading;
    line-height: 1.25;
    font-weight: 600;
}

a,
a:active,
a:focus,
a:hover {
    color: $heading;
    text-decoration: none;
    @include transition-duration(500ms);
    outline: none;
    font-weight: 500;
}

li {
    list-style: none;
}

p {
    line-height: 1.8;
    color: $text;
    font-size: 16px;
    font-weight: 400;
}

img {
    max-width: 100%;
    height: auto;
}

ul {
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
    }
}

.bg-overlay {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: rgba(0, 0, 0, 0.6);
    }
}

.bg-gradient-overlay {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #c63fa4;
        background-image: -moz-linear-gradient(120deg, rgb(223, 66, 177) 0%, rgb(80, 90, 221) 100%);
        background-image: -webkit-linear-gradient(120deg, rgb(223, 66, 177) 0%, rgb(80, 90, 221) 100%);
        background-image: -ms-linear-gradient(120deg, rgb(223, 66, 177) 0%, rgb(80, 90, 221) 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-dark {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #34495e;
        background-image: -moz-linear-gradient(120deg, #2980b9 0%, #34495e 100%);
        background-image: -webkit-linear-gradient(120deg, #2980b9 0%, #34495e 100%);
        background-image: -ms-linear-gradient(120deg, #2980b9 0%, #34495e 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-dark-red {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #b33939;
        background-image: -moz-linear-gradient(120deg, #e84118 0%, #b33939 100%);
        background-image: -webkit-linear-gradient(120deg, #e84118 0%, #b33939 100%);
        background-image: -ms-linear-gradient(120deg, #e84118 0%, #b33939 100%);
        opacity: 0.9;
    }
}


.bg-gradient-overlay-dark-green {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #218c74;
        background-image: -moz-linear-gradient(120deg, #05c46b 0%, #218c74 100%);
        background-image: -webkit-linear-gradient(120deg, #05c46b 0%, #218c74 100%);
        background-image: -ms-linear-gradient(120deg, #05c46b 0%, #218c74 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-green {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #218c74;
        background-image: -moz-linear-gradient(120deg, #64af26 0%, #97c63a 100%);
        background-image: -webkit-linear-gradient(120deg, #64af26 0%, #97c63a 100%);
        background-image: -ms-linear-gradient(120deg, #64af26 0%, #97c63a 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-dark-purple {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #6b0099;
        background-image: -moz-linear-gradient(120deg, #9400d3 0%, #6b0099 100%);
        background-image: -webkit-linear-gradient(120deg, #9400d3 0%, #6b0099 100%);
        background-image: -ms-linear-gradient(120deg, #9400d3 0%, #6b0099 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-orange {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #fe8a39;
        background-image: -moz-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        background-image: -webkit-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        background-image: -ms-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-orange-2 {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #fe8a39;
        background-image: -moz-linear-gradient(147deg, #fe8a39 0%, #fd6938 74%);
        background-image: -webkit-linear-gradient(147deg, #fe8a39 0%, #fd6938 74%);
        background-image: -ms-linear-gradient(147deg, #fe8a39 0%, #fd6938 74%);
        opacity: 0.9;
    }
}



.bg-gradient-overlay-virtualpresentation {
    position: relative;
    z-index: 1;

    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #3fc66c;
        background-image: -moz-linear-gradient(120deg, rgb(66, 223, 92) 0%, rgb(80, 90, 221) 100%);
        background-image: -webkit-linear-gradient(120deg, rgb(66, 223, 92) 0%, rgb(80, 90, 221) 100%);
        background-image: -ms-linear-gradient(120deg, rgb(66, 223, 92) 0%, rgb(80, 90, 221) 100%);
        opacity: 0.9;
    }
}

.bg-gradient-overlay-2 {
    position: relative;
    z-index: 1;

    &::after {
        border-radius: 10px;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: rgba(0, 0, 0, 0.6);
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.8)), color-stop(30%, rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0)));
        background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
        background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0) 100%);
    }
}

.bg-img {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.jarallax {
    position: relative;
    z-index: 0;

    .jarallax-img {
        position: absolute;
        object-fit: cover;
        font-family: 'object-fit: cover';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.bg-boxshadow {
    @include box-shadow(0px 8px 27px 0px rgba(90, 111, 120, 0.15));
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    position: fixed;
    z-index: 100;
    color: $white;
    right: 20px;
    text-align: center;
    padding-right: 0;
    width: 100%;
    top: 20px;
    width: 30px;
    background: $hover;
    height: 30px;
    line-height: 30px;
}

img.mfp-img {
    @include box-shadow($box-shadow);
}

button.mfp-close,
button.mfp-arrow {
    background: $hover;
}

.mfp-bottom-bar {
    display: none !important;
}

.mfp-bg {
    background-color: $dark;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gray {
    background-color: $bg-gray !important;
}

textarea:focus,
input:focus {
    outline: none;
}

textarea:invalid,
input:invalid,
textarea:required,
input:required {
    box-shadow: none !important;
}

.border {
    border-color: $border;
}

.mfp-arrow-left::after {
    border-right-color: transparent;
}

.mfp-arrow-left::before {
    border-right-color: $white;
}

.mfp-arrow-right::after {
    border-left-color: transparent;
}

.mfp-arrow-right::before {
    border-left-color: $white;
}
