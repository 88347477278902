/* :: 14.0 Blog Area CSS */

.our-blog-area {
    .row {
        margin-left: -20px;
        margin-right: -20px;

        @media #{$breakpoint-lg} {
            margin-left: -15px;
            margin-right: -15px;
        }

        @media #{$breakpoint-xs} {
            margin-left: -15px;
            margin-right: -15px;
        }

        .col-12 {
            padding-left: 20px;
            padding-right: 20px;

            @media #{$breakpoint-lg} {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media #{$breakpoint-xs} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.single-blog-area {
    position: relative;
    z-index: 1;
    margin-bottom: 70px;

    .single-blog-thumb {
        border-radius: 10px 10px 0 0;
        overflow: hidden;

        img {
            border-radius: 10px 10px 0 0;
            @include transition-duration(800ms);
        }
    }

    .single-blog-thumb2 {
        border-radius: 10px 10px 0 0;
        overflow: hidden;
    }

    .single-blog-text {
        background-color: $white;
        padding: 30px 30px 0px 30px;
        border-radius: 0 0 10px 10px;

        .blog-title {
            font-size: 20px;
            font-weight: 600;
            color: $text-3;
            margin-bottom: 5px;
            display: inline-block;
            @include transition-duration(500ms);

            &:hover,
            &:focus {
                color: $primary;
            }
        }

        .post-meta {
            margin-bottom: 15px;

            a {
                color: $text-2;
                font-size: 14px;
                margin-right: 20px;
                @include transition-duration(500ms);

                i {
                    margin-right: 5px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }

                @media #{$breakpoint-lg} {
                    margin-right: 15px;
                    font-size: 12px;
                }

                @media #{$breakpoint-xs} {
                    margin-right: 15px;
                    font-size: 12px;
                }
            }
        }

        p {
            color: $text-4;
            margin-bottom: 0;
        }
    }

    &:hover,
    &:focus {
        .single-blog-thumb {
            img {
                @include transform(scale(1.2));
            }
        }
    }

    &.style-2 {
        background-color: $white;
        filter: drop-shadow(3px 3px 6px rgba(0,0,0,0.3));
        border-radius: 10px;
    }
}

.blog-btn {
    position: absolute;
    z-index: 22;
    bottom: -25.5px;
    left: 50%;
    @include transform(translateX(-50%));

    a {
        height: 51px;
        width: 51px;
        background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
        background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 47px;
        color: $white;
        font-size: 25px;
        display: inline-block;
    }
}

.blog-btn-green {
    position: absolute;
    z-index: 22;
    bottom: -25.5px;
    left: 50%;
    @include transform(translateX(-50%));

    a {
        height: 51px;
        width: 51px;
        background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(66, 223, 74) 100%);
        background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(66, 223, 79) 100%);
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 47px;
        color: $white;
        font-size: 25px;
        display: inline-block;
    }
}

.blog-btn-orange {
    position: absolute;
    z-index: 22;
    bottom: -25.5px;
    left: 50%;
    @include transform(translateX(-50%));

    a {
        height: 51px;
        width: 51px;
        background-image: -webkit-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        background-image: -ms-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 47px;
        color: $white;
        font-size: 25px;
        display: inline-block;
    }
}

.blog-btn-orange-2 {
    position: absolute;
    z-index: 22;
    bottom: -25.5px;
    left: 50%;
    @include transform(translateX(-50%));

    a {
        height: 51px;
        width: 51px;
        background-image: -webkit-linear-gradient(147deg, #fe8a39 0%, #fd6938 74%);
        background-image: -ms-linear-gradient(147deg, #fe8a39 0%, #fd6938 74%);
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 47px;
        color: $white;
        font-size: 25px;
        display: inline-block;
    }
}



/* :: Blog Details Page CSS */

.post-details-content {
    position: relative;
    z-index: 1;

    .post-blog-thumbnail {
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        img {
            border-radius: 10px;
        }
    }

    .post-title {
        color: $text-3;
        font-size: 25px;
    }

    .post-meta {
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        a {
            color: $text-2;
            font-size: 14px;
            font-weight: 400;
            margin-right: 15px;

            i {
                margin-right: 5px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    img {
        border-radius: 10px;
    }
}

.confer-blockquote {
    padding-left: 10%;
    margin: 30px 0;

    h5 {
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 1.6;
        color: $text-3;
        border-left: 3px solid $primary;
        padding-left: 10px;
        margin-bottom: 0;
    }
}

.post-tags-social-area {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $border-7;

    .popular-tags {
        position: relative;
        z-index: 1;
        @include flex(0 0 50%);
        max-width: 50%;
        width: 50%;

        p {
            margin-bottom: 0;
            margin-right: 10px;
            color: #505add;
        }

        ul {
            li {
                a {
                    position: relative;
                    z-index: 1;
                    font-size: 16px;
                    color: $text-2;
                    font-weight: 400;
                    margin-right: 25px;

                    @media #{$breakpoint-xs} {
                        font-size: 14px;
                    }

                    &::after {
                        position: absolute;
                        right: -15px;
                        content: "/";
                        top: 0;
                        z-index: 1;
                    }

                    &:hover,
                    &:focus {
                        color: #505add;
                    }
                }

                &:last-child {
                    a {
                        margin-right: 0;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .author-social-info {
        position: relative;
        z-index: 1;
        @include flex(0 0 50%);
        max-width: 50%;
        width: 50%;
        text-align: right;

        a {
            display: inline-block;
            font-size: 16px;
            color: $text-2;
            margin-right: 15px;

            @media #{$breakpoint-xs} {
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover,
            &:focus {
                color: #505add;
            }
        }
    }
}

.post-author-area {
    position: relative;
    z-index: 1;
    padding: 30px 35px;
    box-shadow: 0px 8px 27px 0px rgba(90, 111, 120, 0.15);
    border-radius: 10px;

    .author-avatar {
        @include flex(0 0 125px);
        max-width: 125px;
        width: 125px;
        border: 3px solid #505add;
        border-radius: 50%;
        margin-right: 20px;

        img {
            border-radius: 50%;
        }

        @media #{$breakpoint-xs} {
            @include flex(0 0 50px);
            max-width: 50px;
            width: 50px;
        }
    }

    .author-content {
        h5 {
            margin-bottom: 5px;

            @media #{$breakpoint-xs} {
                font-size: 14px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 16px;
            }
        }

        span {
            font-size: 14px;
            color: $primary;
            margin-bottom: 15px;
            display: block;

            @media #{$breakpoint-xs} {
                font-size: 12px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 14px;
            }
        }

        p {
            margin-bottom: 0;

            @media #{$breakpoint-xs} {
                font-size: 12px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 14px;
            }
        }
    }
}

.pager-area {
    position: relative;
    z-index: 1;

    .pager-single-post {
        @include flex(0 0 50%);
        max-width: 50%;
        width: 50%;

        .post-thumb {
            @include flex(0 0 60px);
            max-width: 60px;
            width: 60px;
            border-radius: 50%;
            margin-right: 15px;

            @media #{$breakpoint-xs} {
                @include flex(0 0 30px);
                max-width: 30px;
                width: 30px;
            }

            a {
                display: block;

                img {
                    border-radius: 50%;
                }
            }
        }

        .post-title {
            font-size: 18px;
            display: block;
            margin-bottom: 5px;
            font-weight: 600;

            @media #{$breakpoint-xs} {
                font-size: 12px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 16px;
            }

            &:hover,
            &:focus {
                color: $hover;
            }
        }

        span {
            font-size: 14px;
            display: block;
            color: $secondary;

            @media #{$breakpoint-xs} {
                font-size: 10px;
            }

            @media #{$breakpoint-xs-landscape} {
                font-size: 12px;
            }
        }

        &:last-child {
            margin-left: auto;
            @include flex-direction-row-reverse(row-reverse);

            .post-thumb {
                margin-right: 0;
                margin-left: 15px;
            }

            .post-meta {
                text-align: right;
            }
        }
    }
}

.comment_area {
    position: relative;
    z-index: 1;

    h4 {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 25px;
    }

    ol {
        margin: 0;
        padding: 0;
    }

    .single_comment_area {
        position: relative;
        z-index: 1;
        border-bottom: 1px solid $border-7;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .comment-content {
        position: relative;
        z-index: 1;
        margin-bottom: 40px;

        .comment-author {
            @include flex(0 0 90px);
            width: 90px;
            max-width: 90px;
            margin-right: 15px;
            border-radius: 50%;

            @media #{$breakpoint-xs} {
                @include flex(0 0 40px);
                width: 40px;
                max-width: 40px;
                margin-right: 15px;
            }

            img {
                border-radius: 50%;
            }
        }

        .comment-meta {
            .comment-meta-data {
                position: relative;
                z-index: 1;
                display: block;
                margin-bottom: 10px;

                a {
                    font-size: 18px;
                    font-weight: 600;
                    margin-right: 15px;
                    display: inline-block;

                    @media #{$breakpoint-xs} {
                        font-size: 14px;
                    }

                    &:focus,
                    &:hover {
                        color: $hover;
                    }
                }

                span {
                    font-size: 14px;
                    color: $secondary;

                    @media #{$breakpoint-xs} {
                        font-size: 12px;
                    }

                    i {
                        margin-right: 10px;
                        display: inline-block;
                    }
                }
            }


            p {
                font-size: 16px;
                margin-bottom: 8px;

                @media #{$breakpoint-xs} {
                    font-size: 13px;
                }
            }

            .reply {
                display: inline-block;
                padding: 0;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                color: $secondary;
                letter-spacing: 0.5px;

                &:focus,
                &:hover {
                    color: $dark;
                }
            }
        }
    }

    .children {
        margin-left: 105px;

        @media #{$breakpoint-xs} {
            margin-left: 60px;
        }

        .single_comment_area {
            border-bottom: none;
            border-top: 1px solid $border-7;
            padding-top: 40px;

            &:last-of-type {
                margin-bottom: 0;
            }

            .comment-author {
                margin-right: 20px;

                @media #{$breakpoint-xs} {
                    margin-right: 10px;
                }
            }
        }
    }
}

.confer-leave-a-reply-form {
    position: relative;
    z-index: 1;

    h4 {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 25px;
    }
}

.balloon1{
    position: absolute;
    padding-top: 20px;
    width: 40px;
    height: 40px;
    line-height: 0px;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    font-size: 20px;
    background-color: #ff9800;
    filter: drop-shadow(3px 3px 6px rgba(0,0,0,0.8));
  }
  .balloon1::before{
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: -3px;
    bottom: 0px;
    border-right: 10px solid #ff9800;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    transform: rotate(-45deg);

  }

  .balloon-outer{
    position: absolute;
    top: 10px;
    right: 50px;
  }

  .blog-slider {
    width: 95%;
    position: relative;
    max-width: 1200px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 15px;
    height: auto;
    transition: all .3s;
    margin: 0px 0px 40px 40px;
  
   
  @media screen and (max-width: 992px) {
    height: auto;
  }
  
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 500px;
    height: auto;
    margin: 120px 0px 70px 0px;
  }

  @media screen and (max-width: 576px) {
    min-height: 500px;
    height: auto;
    margin: 20px 0px 60px 0px;
  }
  
  
  @media screen and (max-height: 500px) and (min-width: 992px) {
        height: auto;
  }
  
  &__item {
    display: flex;
    align-items: center;
    
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
    
    &.swiper-slide-active {
      .blog-slider__img {
        img {
          opacity: 1;
        transition-delay: .3s;
        }
      }
      .blog-slider__content {
        > * {
      
        opacity: 1;
        transform: none;

      @for $i from 0 to 15 {
        &:nth-child(#{$i + 1}) {
          transition-delay: $i * 0.1 + 0.3s;
        }
      }

    }
      }
    }
    
  }
  
  &__img {
    width: 40%;
    //width: 300px;
    flex-shrink: 0;
    //height: 300px;
    box-shadow: 4px 13px 30px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transform: translateX(-80px);
   
    overflow: hidden;
    
    &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      border-radius: 10px;
    opacity: 0.8;
}
    
    img {
      width: 100%;
    height: 100%;
    object-fit: cover;
      display: block;
       opacity: 1;
      border-radius: 10px;
    transition: all .3s;
    }
    
    
    @media screen and (max-width: 992px) {
      // width: 45%;
    }
    @media screen and (max-width: 768px) {
    transform: translateY(-50%);
      width: 90%;
  }
    @media screen and (max-width: 576px) {
      width: 95%;
    }
     @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 270px;
  }
  }
  
  &__content {
    // width: 60%;
    padding-right: 25px;
    @media screen and (max-width: 992px) {
      // width: 55%;
    }
    @media screen and (max-width: 768px) {
          margin-top: -140px;
    text-align: center;
    padding: 0 30px;
    }
    
    @media screen and (max-width: 576px) {
        margin-top: -80px;
      padding: 0
    }
    > * {
      opacity: 1;
      transform: translateY(25px);
      transition: all .4s;
      
      
    }
    
  }
  
  &__code {
    color: #7b7992;
    margin-bottom: 15px;
    display: block;
    font-weight: 500;
  }
  
  &__title {
    font-size: calc(112.5% + 1vw);
    font-weight: 700;
    color: #0d0925;
  }
  
  &__text {
    color: #4e4a67;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  
  &__button {
        display: inline-flex;
    background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    padding: 15px 35px;
    border-radius: 50px;
    color: #fff;
    box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
    text-decoration: none;
    font-weight: 500;
        justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 20px;
    @media screen and (max-width: 576px) {
      width: 100%;
    }
    
  }
  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%;
    
}
  &__pagination {
   position: absolute;
    z-index: 21;
    right: 20px;
    width: 11px!important;
    text-align: center;
    left: auto!important;
    top: 50%;
    bottom: auto!important;
    transform: translateY(-50%);
    @media screen and (max-width: 768px) {
     transform: translateX(-50%);
          left: 50%!important;
      top: 205px;
    width: 100%!important;
    display: flex;
    justify-content: center;
    align-items: center;
    }
     &.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 8px 0;
       @media screen and (max-width: 768px) {
         margin: 0 5px;
       }
}
    
    .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: block;
    border-radius: 10px;
    background: #062744;
    opacity: 0.2;
      transition: all .3s;
      &-active {
    opacity: 1;
    background: #fd3838;
    height: 30px;
    box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
        
        @media screen and (max-width: 768px) {
          height: 11px;
          width: 30px;
        }
}
}
    
  }
  
}

