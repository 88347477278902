/* this file contains custom css configs for RoboCup 2023 Bordeaux, France 
Color theme is based on white-blue-red (France Theme) */
$france-red: #ed2939;

$france-blue: #2940b9;

/* Dropdown Content (Hidden by Default) */


.bg-gradient-overlay-blue {
    position: relative;
    z-index: 1;

    &:after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: #34495e;
        background-image: -moz-linear-gradient(120deg, $france-blue 0%, #2980b9 100%);
        background-image: -webkit-linear-gradient(120deg, $france-blue 0%, #2980b9 100%);
        background-image: -ms-linear-gradient(120deg, $france-blue 0%, #2980b9 100%);
        opacity: 0.9;
    }
}

.section-heading-france-red {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;

    p {
        font-size: 20px;
        color: $france-red;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0;
        display: inline-block;
        font-weight: 400;
    }

    h4 {
        font-size: 38px;
        color: $text-3;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1px;

        @media #{$breakpoint-xs} {
            font-size: 26px;
        }

        @media #{$breakpoint-sm} {
            font-size: 30px;
        }
    }
}

.section-heading-france-blue {
    position: relative;
    z-index: 1;
    margin-bottom: 60px;

    p {
        font-size: 20px;
        font-weight: bold;
        color: $france-blue;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 0;
        display: inline-block;
        font-weight: 400;
    }

    h4 {
        font-size: 38px;
        color: $text-3;
        margin-bottom: 0;
        text-transform: uppercase;
        letter-spacing: 1px;

        @media #{$breakpoint-xs} {
            font-size: 26px;
        }

        @media #{$breakpoint-sm} {
            font-size: 30px;
        }
    }
}

.blog-btn-france {
    position: absolute;
    z-index: 22;
    bottom: -25.5px;
    left: 50%;
    @include transform(translateX(-50%));

    a {
        height: 51px;
        width: 51px;
        background-image: -webkit-linear-gradient(40deg, $france-blue 30%, $france-red 70%);
        background-image: -ms-linear-gradient(40deg,  $france-blue 30%, $france-red 70%);
        text-align: center;
        border: 2px solid $white;
        border-radius: 50%;
        line-height: 47px;
        color: $white;
        font-size: 25px;
        display: inline-block;
    }
}


.confer-btn-custom {
    position: relative;
    z-index: 1;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    display: inline-block;
    padding: 0 25px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: $white;
    border-radius: 23px;
    @include transition-duration(500ms);
    overflow: hidden;

    i {
    margin-left: 5px;
    }

    &::before {
        @include transition-duration(800ms);
        position: absolute;
        width: 120%;
        height: 120%;
        top: -10%;
        left: -10%;
        content: '';
        background: $primary;
        z-index: -2;
    }

    &:focus,
    &:hover {
        font-weight: 500;
        @include box-shadow($box-shadow);

        i {
            @include animation(buttonIcon 1s ease-in infinite);
        }

        &::before {
            left: 110%;
        }
    }
    &.blue {
        background-image: -webkit-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
        background-image: -ms-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
        &::before {
            background-image: -webkit-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
            background-image: -ms-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
        }
        &:focus,
        &:hover {
            background-image: -webkit-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
            background-image: -ms-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
            color: white !important;
        }
    
    }
    &.france {
        background-image: -webkit-linear-gradient(0deg, $france-blue 0%,#2960e9 33%, #ddd 34%, white 66%, #f00 67%, #b11414 100%);
        background-image: -ms-linear-gradient(0deg, $france-blue 0%, #2960e9 33%, #ddd 34%, white 66%, #f00 67%, #b11414 100%);
        &::before {
            background-image: -webkit-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
            background-image: -ms-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
        }
        &:focus,
        &:hover {
            background-image: -webkit-linear-gradient(0deg, $france-blue 0%,#2960e9 33%, #ddd 34%, white 66%, #f00 67%, #b11414 100%);
            background-image: -ms-linear-gradient(0deg, $france-blue 0%, #2960e9 33%, #ddd 34%, white 66%, #f00 67%, #b11414 100%);
            color: black !important;

        }
    
    }
    &.red-blue {
        background-image: -webkit-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
        background-image: -ms-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
        &::before {
            background-image: -webkit-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
            background-image: -ms-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
        }
        &:focus,
        &:hover {
            background-image: -webkit-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
            background-image: -ms-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
            color: white !important;
        }
    
    }
    &.blue-red {
        background-image: -webkit-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
        background-image: -ms-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
        &::before {
            background-image: -webkit-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
            background-image: -ms-linear-gradient(23deg, $france-blue 0%, #2980d9 74%);
        }
        &:focus,
        &:hover {
            background-image: -webkit-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
            background-image: -ms-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
            color: white !important;
        }
    
    }
    &.red {
        background-image: -webkit-linear-gradient(147deg, #ff2424  0%, #a40e0e 74%);
        background-image: -ms-linear-gradient(147deg, #ff2424 0%, #a40e0e 74%);
        &::before {
            background-image: -webkit-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
            background-image: -ms-linear-gradient(147deg, #a40e0e 0%, #ff2424 74%);
        }
        &:focus,
        &:hover {
            background-image: -webkit-linear-gradient(147deg, #ff2424  0%, #a40e0e 74%);
            background-image: -ms-linear-gradient(147deg, #ff2424 0%, #a40e0e 74%);
            color: white !important;
        }
    }
    &.blue-white {
        background: white;
        &::before {
            background-image: -webkit-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
            background-image: -ms-linear-gradient(23deg, #2980b9 0%, $france-blue 74%);
        }
        &:focus,
        &:hover {
            background: white;
            color: black !important;
        }
    
    }
    &.green-white {
        background: white;
        &::before {
            background-image: -webkit-linear-gradient(23deg, #6f9b4a 0%, #97c63a 74%);
            background-image: -ms-linear-gradient(23deg, #6f9b4a 0%, #97c63a 74%);
        }
        &:focus,
        &:hover {
            background: white;
            color: black !important;
        }
    
    }
    &.white-simple {
        border: 2px solid $white;
        @include transition-duration(500ms);
        &::before {
            background: transparent;
            color: white;
        }
        &:focus,
        &:hover {
            color: black !important;
        }
    
    }
}