/* :: 12.0 Speaker Area CSS */

.our-speaker-area {
    position: relative;
    z-index: 1;

    .row {
        margin-left: -20px;
        margin-right: -20px;

        @media #{$breakpoint-xs} {
            margin-left: -15px;
            margin-right: -15px;
        }

        .col-12 {
            padding-left: 20px;
            padding-right: 20px;

            @media #{$breakpoint-xs} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.single-speaker-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 40px;
    border-radius: 10px;

    .speaker-single-thumb {
        position: relative;
        z-index: 1;
        border-radius: 10px;

        img {
            @include transition-duration(1500ms);
            border-radius: 10px;
            width: 100%;
        }
    }

    .social-info {
        position: absolute;
        top: -180px;
        right: 0;
        z-index: 22;
        background-color: $primary;
        padding: 15px;
        border-radius: 0 10px 0 10px;
        text-align: center;
        @include transition-duration(800ms);

        a {
            display: block;
            font-size: 18px;
            color: $white;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .speaker-info {
        position: absolute;
        bottom: 25px;
        left: 25px;
        z-index: 22;

        h5 {
            font-size: 20px;
            font-weight: 500;
            color: $white;
            letter-spacing: 1px;
            margin-bottom: 10px;
        }

        p {
            color: $primary;
            margin-bottom: 0;
            letter-spacing: 1px;
            line-height: 1;
        }
    }

    &:hover,
    &:focus {
        .social-info {
            top: 0;
        }
    }
}