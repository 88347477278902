/* :: 3.0 Preloader Area CSS */

#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    top: 0;
    left: 0;
    
    @include display-flex(flex);
    @include align-items-center;
    @include justify-content-center;
    overflow: hidden;
}

.l-def {
    background: $primary;
    background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
    background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(223, 66, 177) 100%);
}

.l-orange {
    background: #fe8a39;
    background-image: -webkit-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
    background-image: -ms-linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
}

.l-green {
    background: rgb(103, 223, 66);
    background-image: -webkit-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(103, 223, 66) 100%);
    background-image: -ms-linear-gradient(120deg, rgb(80, 90, 221) 0%, rgb(103, 223, 66) 100%);
}

.loader {
    position: relative;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @include animation(loader linear 2s infinite);
    border-top: 2px solid $white;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

@-webkit-keyframes loader {

    0% {
        @include transform(rotate(0deg));
    }

    100% {
        @include transform(rotate(360deg));
    }
}

@keyframes loader {

    0% {
        @include transform(rotate(0deg));
    }

    100% {
        @include transform(rotate(360deg));
    }
}