@mixin display-flex($value) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: $value;
}

@mixin flex($value) {
    -webkit-box-flex: 0;
    -ms-flex: $value;
    flex: $value;
}

@mixin flex-wrap($value) {
    -ms-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin order($value) {
    -webkit-box-ordinal-group: $value + 1;
    -ms-flex-order: $value;
    order: $value
}

@mixin flex-direction-row($value) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-direction-row-reverse($value) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-direction-column($value) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin flex-direction-column-reverse($value) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

@mixin justify-content-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@mixin justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@mixin justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin justify-content-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

@mixin align-items-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@mixin align-items-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}