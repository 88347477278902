/* ------------------------------------------
[Master Stylesheet]

Template Name: Confer - Conference Event HTML Template
Template Author: Colorlib
Template Author URL: http://colorlib.com
Version: 1.0.0
Last Update: March 14, 2019

[Tables of CSS Content]

+ body

    01.0 Reboot Area CSS
    02.0 Spacing Area CSS
    03.0 Preloader Area CSS
    04.0 Heading Area CSS
    05.0 Backtotop Area CSS
    06.0 Buttons Area CSS
    07.0 Header Area CSS
    08.0 Hero Area CSS
    09.0 Footer Area CSS
    10.0 About Area CSS
    11.0 Counter Area CSS
    12.0 Speakar Area CSS
    13.0 Breadcrumb Area CSS
    14.0 Blog Area CSS
    15.0 Widget Area CSS
    16.0 Client Area CSS
    17.0 Schedule Area CSS
    18.0 Ticket Area CSS
    19.0 Sponsor Area CSS
    20.0 Contact Area CSS
    21.0 CTA Area CSS
--------------------------------------------- */

/* Import Fonts & All CSS */

@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700');
@import 'css/bootstrap.min.css';
@import 'css/animate.css';
@import 'css/default-assets/classy-nav.css';
@import 'css/owl.carousel.min.css';
@import 'css/magnific-popup.css';
@import 'css/font-awesome.min.css';
@import 'css/material-design-iconic-font.min.css';
@import 'css/w3.css';
@import 'css/style.css';

// Utilities

@import "utilities/fonts";
@import "utilities/variables";
@import "utilities/responsive";
@import "utilities/color";

// Mixins

@import "mixins/flex";
@import "mixins/miscellaneous";

// Stylesheet

@import "reboot";
@import "spacing";
@import "preloader";
@import "heading";
@import "backtotop";
@import "buttons";
@import "header";
@import "hero";
@import "footer";
@import "about";
@import "counter";
@import "speakar";
@import "breadcrumb";
@import "blog";
@import "widget";
@import "client";
@import "schedule";
@import "ticket";
@import "sponsor";
@import "contact";
@import "cta";
@import "algolia";
@import "calender";
@import "custom";
@import "france";