// Heading Color
$heading: #111343;

// Text Color
$text: #5d5e8d;

// Secondary Color
$secondary: #9293bc;

// Primary Color
$primary: #df42b1;

// Hover Color
$hover: #df42b1;

// White Color
$white: #ffffff;

// Dark Color
$dark: #000000;

// Text Dark
$text-dark: #161715;

// Text-2 Color
$text-2: #9293bc;

// Text-3 Color
$text-3: #111343;

// Text-3 Color
$text-4: #5d5e8d;

// Text Gray
$text-gray: #625b66;

// Blue Background
$bg-blue:#151853;

// Blue Background
$bg-blue-2:#1f2371;

// Gray Background
$bg-gray: #f7f7f7;

// Dark-2 Color
$dark-2: #5f608e;

// Dark 2 Color
$dark2: #0e2737;

// Border Color
$border: #4b4d7b;

// Border-2 Color
$border-2: #4c4f8d;

// Border-2 Color
$border-3: #444675;

// Border-4 Color
$border-4: #4d507d;

// Border-5 Color
$border-5: #3d3e66;

// Border-6 Color
$border-6: #414269;

// Border-7 Color
$border-7: #dbdcf1;