
//news
.msr_newslist02 {
  width: 100%;

  li {
      font-size: 14px;
      background-color: #F3F3F3;
  
      &:nth-child(odd) {
          background-color: #E6E6E6;
      }
      a {
          box-sizing: border-box;
          color: #000000;
          display: table;
          padding: 10px;
          text-decoration: none;
          transition: 0.2s ease-in-out;
          -o-transition: 0.2s ease-in-out;
          -moz-transition: 0.2s ease-in-out;
          -webkit-transition: 0.2s ease-in-out;
          width: 100%;
      
          &:hover {
              text-decoration: none;
              background-color: #FDFDFD;
          }
      }

      div {
          display: table-cell;
          width: 144px;
      }
      p {
          display: table-cell;
          padding-left: 20px;
          vertical-align: middle;
      }

      time {
          display: table-cell;
          vertical-align: top;
          width: 90px;
          font-size:20px;
          padding-right: 5px;
      }

      .cat01, .cat02 {
          background-color: #e74c3c;
          border-radius: 1px;
          color: #FFFFFF;
          font-size: 15px;
          padding: 0 2px;
          text-align: center;
          width: 50px;
      }
      .cat02 {
          background-color: #0E3A5F;
      }   
  }
}
.left {
left: 33% !important;
}
.right {
left: 66% !important;
} 

.link-t-a {
  position: relative;
  top: -60px;
  display: block;
}

.documents-table {
  width: 100%;
  tr {
    td {
      border: none;
      width: 50%;
      vertical-align: top;
      text-align: center;
      .after:after {
        content: "";
        background: black;
        position: absolute;
        bottom: 10%;
        left: 0;
        top: 10%;
        width: 1px;
        }
      div {
        position:relative;
        a {
          @extend .mb-15;
        }
        p {
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}

.scores {
  .single-blog-area {
    margin-bottom: 0px;
  }

  .league-title {
    padding: 15px 30px;

    p {
      font-size: 25px;
      font-weight: 600;
      color: #337;
      margin-bottom: 0px;
    }
  }
  .file-list-item {
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    .icon {
      font-size: 30px;
      width: 20%;
    }
    .info {
      display: flex;
      flex-direction: column;
      width:70%;

      .title {
        line-height: 27px;
        font-size: 20px;
      }

      .type {
        font-size: 10px;
        color: #777;
      }
    }
    .download-area {
      width: 10%;
      text-align: right;
      a {
        font-size: 30px;
        i {
        }
      }
    }
  }
  hr {
    margin: 0px;

    &.dark {
      margin:auto;
      width:85%;
      transform:translateX(0px);
      border-top: 1px solid #ccc;
    }
  }
}
.doc-card {
  hr {
    border-top: 1px solid #ccc;
  }
}
.overflow-bottom {
overflow: visible !important;
clip-path: inset(0% 0% -20% 0%);
}

#dropdown-content {
@media (min-width: 990px) {
    left: 100%;
}
}

#Archive {
  text-align: center;
  padding: 0px;
  background-color: #fafafa;
  .accordion-section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    
    border-radius: 10px;
    width: fit-content; 
    margin: auto; 
  }
  .accordion-text {
    flex-grow: 1;
  }
  .accordion-caret::after {
    content: "▼";
    margin: 0 5px;
  }
  section[expanded] .accordion-caret::after {
    content: "▲";
  }
}

// Sub-League cards on home and RC-Event Overview. Center in div
.league-cards {
margin: 0px auto;
}
.league-card-middle {
@media screen and (min-width:990px) {
    margin-left: -50px; 
    margin-right: -50px;
}
}
.anchor {
display: block;
position: relative;
top: -75px;
visibility: hidden;
}

//
.m-auto {
margin-left: auto;
margin-right: auto;
}

.download {
&:hover,
&:after {
    color: $primary;
}
}

.nohover {
&:hover,
&:focus {
color: $dark !important;
}
}

.po-re{
position:relative;
}
.m-aw {
margin-top:80px;
margin-bottom: 50px;
}
// Award Podium Individual Teams
.podium {
height: 400px;
max-width: 1000px;
.podium-single {
  width:32.5%;
  position:relative;
  margin-left: auto;
  margin-right: auto;

  h2 {
    color:white;
    position: absolute;
    width: 100%;
    font-size: 30pt;

    &.bt-silver {
      bottom: 215px;
    }
    &.bt-gold {
      bottom: 290px;
    }
    &.bt-bronze {
      bottom: 165px;
    }
  }
}
.blog-slider {
  min-height: auto;
  margin:0px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  p {
    position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);
    font-weight:bold;
  }
  i {
    position: absolute;top: 50%;left: 50%;-ms-transform: translate(-50%, -50%);transform: translate(-50%, -50%);
    color: white;
  }
  &.gold{
    height: 275px;
    background: gold;
    background: 
    radial-gradient(ellipse farthest-corner at left top, #FEEB37 0%, #FDB931 30%, #E0a52b 55%, #cf9825 100%, transparent 80%);
    i {
      font-size: 100pt;
    }
    p {
      color: #E0a52b;
      top: 44%;
      font-size:45pt;
    }
  }
  &.silver {
    height: 200px;
    background: silver;
    background: 
    radial-gradient(ellipse farthest-corner at right top, #e0e0e0 0%, #c0c0c0 8%, #a0a0a0 30%, #909090 60%, #858585 80%, #7a7a7a 100%, transparent 80%);
    i {
      font-size: 80pt;
    }
    p {
      color: #909090;
      top: 42%;
      font-size:38pt;
    }
  }
  &.bronze {
    height: 150px;
    background: brown;
    background: radial-gradient(ellipse farthest-corner at right top,#fda974 0,#eba158 8%,#de9e44 20%,#c28540 40%,#c37726 70%,#b46817 100%,transparent 80%);
    i {
      font-size: 60pt;
    }
    p {
      color: #c28540;
      top: 42%;
      font-size:30pt;
    }
  }
}
&.super {
  height: 350px;
  margin:auto;
  display: flex;
  justify-content: center;
  
  i {
    position: absolute;
    font-size: 250pt;
    bottom:0px;
    z-index:1;
  }
  #nr1 {
    font-size: 110pt;
    color: white;
    position: absolute;
    z-index:2;
    top: 12%;
  }
  .gold {
    cursor: pointer;
    background: yellow;
    background: -webkit-radial-gradient(ellipse farthest-corner at left top, #FFFFAA 0%, #FEEB37 13%, #FDB931 50%, #E0a52b 70%, #cf9825 100%);
    background: radial-gradient(ellipse farthest-corner at left top, #FFFFAA 0%, #FEEB37 13%, #FDB931 50%, #E0a52b 70%, #cf9825 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .super-teams {
    height:100%;
    position:absolute;
    width:100%;
    display:flex;
    justify-content:center;

    h2 {
      color: white;
      font-size: 30pt;
      position: absolute;
      bottom: 60px;
      width: 100%;
    
      &.third-team {
        bottom:auto;
        top: -10% !important;
      }
    }
    .left-team {
      left:-9%;
      width: 200px;
    }
    .right-team {
      right:-9%;
      width: 200px;
    }
    .blog-slider {
      height: 60px;
      background-color: #fff1;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
&.certificate {
  height: fit-content;
  
  .podium-single {
    width:42%;
    min-width:350px;
    height:200px;
    margin-left:auto;
    margin-right:auto;

    .blog-slider {
      height:100%;
      background: #fff0;

      i {
        font-size:200pt;
        position:absolute;
        color:#ffd70077;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .col-12 {
        margin-bottom:10px;
      }
      .award {
        font-size:20pt;
        color:white;
      }
      .team {
        font-size:30px;
        color: white;
      }
      hr {
        margin:auto;
        width:80%;
        transform:translateX(0px);
      }
    }
  }
}
}

//Podium scaled max-width 700px
@media screen and (max-width: 700px) {
.m-aw {
  margin-top:50px;
  margin-bottom: 30px;
}
.podium {
  height: 300px;
  .podium-single {
    h2 {
      font-size: 22pt;
      &.bt-silver {
        bottom: 160px;
      }
      &.bt-gold {
        bottom: 210px;
      }
      &.bt-bronze {
        bottom: 120px;
      }
    }
  }
  .blog-slider {
    &.gold{
      height: 200px;
      i {
        font-size: 80pt;
      }
      p {
        font-size: 35pt;
      }
    }
    &.silver {
      height: 150px;
      i {
        font-size: 62pt;
      }
      p {
        font-size: 27pt;
      }
    }
    &.bronze {
      height: 110px;
      i {
        font-size: 44pt;
      }
      p {
        font-size: 22pt;
      }
    }
  }
  &.super {
    height: 290px;
    i {
      position: absolute;
      font-size: 200pt;
      bottom:0px;
      z-index:1;
    }
    #nr1 {
      font-size: 88pt;
      top: 16%;
    }
    .super-teams {
      h2 {
        font-size: 22pt;
        bottom: 50px;
      
        &.third-team {
          top:-7% !important;
        }
      }
      .left {
        left:-9%;
        width: 180px;
      }
      .right {
        right:-9%;
        width: 180px;
      }
      .blog-slider {
        height: 50px;
      }
    }
  }
}
}

//Podium scaled max-width 500px
@media screen and (max-width: 500px) {
.m-aw {
  margin-top:25px;
  margin-bottom: 15px;
}
.podium {
  height: 150px;
  .podium-single {
    h2 {
      font-size: 15pt;
      &.bt-gold {
        bottom: 128px;
      }
      &.bt-silver {
        bottom: 98px;
      }
      &.bt-bronze {
        bottom: 75px;
      }
    }
  }
  .blog-slider {
    &.gold{
      height: 120px;
      i {
        font-size: 50pt;
      }
      p {
        font-size: 26.5pt;
      }
    }
    &.silver {
      height: 90px;
      i {
        font-size: 34pt;
      }
      p {
        font-size: 17pt;
      }
    }
    &.bronze {
      height: 66px;
      i {
        font-size: 25pt;
      }
      p {
        font-size: 14pt;
      }
    }
  }
  &.super {
    height: 225px;
    i {
      position: absolute;
      font-size: 150pt;
      bottom:0px;
      z-index:1;
    }
    #nr1 {
      font-size: 66pt;
      top: 18%;
    }
    .super-teams {
      h2 {
        font-size: 18pt;
        bottom: 40px;
      
        &.third-team {
          top:-4% !important;
        }
      }
      .left {
        left:-9%;
        width: 150px;
      }
      .right {
        right:-9%;
        width: 150px;
      }
      .blog-slider {
        height: 40px;
      }
    }
  }
}
}