/* :: 18.0 Tickets Area CSS */

.our-ticket-pricing-table-area {
    position: center;
    z-index: 1;

    .row {
        margin-right: -30px;
        margin-left: -30px;

        @media #{$breakpoint-lg} {
            margin-left: -15px;
            margin-right: -15px;
        }

        @media #{$breakpoint-md} {
            margin-left: -15px;
            margin-right: -15px;
        }

        @media #{$breakpoint-xs} {
            margin-left: -15px;
            margin-right: -15px;
        }

        .col-12 {
            padding-left: 30px;
            padding-right: 30px;

            @media #{$breakpoint-lg} {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media #{$breakpoint-md} {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media #{$breakpoint-xs} {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.single-ticket-pricing-table {
    background-color: $white;
    padding: 0 40px 40px 40px;
    border-radius: 10px;
    @include transition-duration(500ms);

    &.active {
        @include transform(scale(1.1));

        @media #{$breakpoint-xs} {
            @include transform(scale(1.05));
        }
    }

    .ticket-plan {
        background-color: $primary;
        padding: 10px 25px;
        display: inline-block;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $white;
        border-radius: 0 0 5px 5px;
        margin-bottom: 25px;
        line-height: 1;
    }

    .ticket-icon {
        img {
            max-height: 100px;

            @media #{$breakpoint-xs} {
                max-height: 60px;
            }
        }
    }

    .ticket-price {
        font-size: 60px;
        color: $text-3;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 1;
        margin-top: 25px;
        margin-bottom: 15px;

        span {
            font-size: 30px;
            font-weight: 600;
            position: relative;
            top: -20px;
        }
    }

    .ticket-pricing-table-details {
        p {
            color: $text-4;
            margin-bottom: 8px;

            @media #{$breakpoint-lg} {
                font-size: 14px;
            }

            i {
                color: $primary;
                margin-right: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-2 {
        background-color: $white;
        box-shadow: 0px 8px 27px 0px rgba(90, 111, 120, 0.15);
    }
}