/* :: 02.0 Spacing Area CSS */

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-70 {
    margin-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

.pt-50 {
    padding-top: 50px !important;
}

.pr-15 {
    padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-80 {
  padding-bottom: 80px;
}

.section-padding-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.section-padding-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section-padding-0-80 {
    padding-top: 0;
    padding-bottom: 80px;
}

.section-padding-80-0 {
    padding-top: 80px;
    padding-bottom: 0;
}

.section-padding-80-50 {
    padding-top: 80px;
    padding-bottom: 50px;
}

.section-padding-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section-padding-0-85 {
    padding-top: 0;
    padding-bottom: 85px;
}

.section-padding-100-60 {
    padding-top: 100px;
    padding-bottom: 60px;
}

.section-padding-100-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section-padding-0-100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.section-padding-100-0 {
    padding-top: 100px;
    padding-bottom: 0;
}

.section-padding-20-0 {
    padding-top: 20px;
    padding-bottom: 0;
}

.section-padding-20-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.section-padding-20-50 {
    padding-top: 20px;
    padding-bottom: 50px;
}

.section-padding-50-20 {
    padding-top: 50px;
    padding-bottom: 20px;
}

.section-padding-50-0 {
    padding-top: 50px;
    padding-bottom: 0px;
}

.section-padding-50-100 {
    padding-top: 50px;
    padding-bottom: 100px;
}