/* :: 7.0 Header Area CSS */

.classy-nav-container {
    background-color: transparent;
    padding: 0;

    .classy-navbar {
        @include transition-duration(500ms);
        height: 105px;
        padding: 0;

        .nav-brand {
            @media #{$breakpoint-xs} {
                max-width: 200px;
                margin-right: 15px;
            }
        }

        .classynav ul li {
            
            a {
                font-size: 16px;
                text-transform: uppercase;
                color: $dark;
                font-weight: 500 !important;
                padding: 0 15px;

                &:hover,
                &:focus {
                    color: $hover;
                }
                @media #{$breakpoint-lg} {
                    font-size: 14px;
                }

                @media #{$breakpoint-md} {
                    font-size: 14px;
                    color: $heading;

                    &:hover,
                    &:focus {
                        color: $hover;
                    }
                }

                @media #{$breakpoint-xs} {
                    font-size: 14px;
                    color: $heading;

                    &:hover,
                    &:focus {
                        color: $hover;
                    }
                }
            }

            &.cn-dropdown-item,
            &.megamenu-item {
                ul li {
                    a {
                        font-size: 13px;
                        text-transform: uppercase;
                        height: 42px;
                        line-height: 42px;
                        color: $heading;
                        padding-left: 10px !important;

                        &:focus,
                        &:hover {
                            color: $hover;
                        }
                        &.active {
                            color: $primary !important;
                        }
                        
                    }
                    
                }
            }
            &.active {
                a {
                    color: $primary;
                }
            }
        }
    }
}

.classynav ul li.has-down > a::after,
.classynav ul li.has-down.active > a::after,
.classynav ul li.megamenu-item > a::after {
    color: $primary;
}

.breakpoint-off .classynav ul li .dropdown,
.breakpoint-off .classynav ul li .megamenu {
    width: auto;
    white-space: nowrap;
    @include box-shadow($box-shadow);
    border-radius: 4px;
}

.breakpoint-off .classynav ul li .megamenu {
    width: 100%;
}

.classy-navbar-toggler .navbarToggler span {
    background-color: #333333;
}

.header-area {
    position: fixed;
    z-index: 999;
    width: 100%;
    @include transition-duration(500ms);
    background-color: rgba(250, 250, 250, 0.80);

    img {
      max-height: 75px;
    }

    &.sticky {
        background-color: $white;
        @include box-shadow($box-shadow);

        .classy-navbar {
            height: 75px;
        }
    }
}